<template>
  <div>
    <v-col align="center">
      <v-card max-width="500px">
        <v-toolbar color="secondary lighten-5" class="mb-5" flat prominent>
          <v-col class="text-center">
            <v-img src="/media/logos/tkt-vivax-logo.png" contain></v-img>
          </v-col>
          <template #extension>
            <v-row>
              <v-col>
                <h1 class="text--secondary">Breitband-Cockpit</h1>
              </v-col>
            </v-row>
          </template>
        </v-toolbar>
        <v-card-text>
          <validation-observer ref="loginForm" v-slot="{ invalid }">
            <form-login v-model="loginData" @submit="login" />
            <v-card-actions>
              <v-btn
                color="primary"
                block
                large
                :loading="loading"
                :disabled="invalid"
                @click="login"
              >
                <v-icon class="mr-2">mdi-login-variant</v-icon>
                Anmelden
              </v-btn>
            </v-card-actions>
          </validation-observer>
        </v-card-text>
        <!--        <v-card-actions-->
        <!--          ><v-btn color="secondary" block text to="/reset-password">-->
        <!--            Passwort vergessen?-->
        <!--          </v-btn></v-card-actions-->
        <!--        >-->
      </v-card>
    </v-col>
  </div>
</template>

<script>
import FormLogin from "@/components/forms/FormLogin";
import { LOGIN } from "@/store/auth.module";

export default {
  name: "Login",
  components: { FormLogin },
  data() {
    return {
      loading: false,
      loginData: {},
    };
  },

  methods: {
    async login() {
      this.loading = true;

      this.$store
        .dispatch(LOGIN, this.loginData)
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "application-setup" });
        })
        .catch(({ response }) => {
          this.loading = false;

          switch (response.status) {
            case 429:
              this.$refs.loginForm.setErrors({
                email: "Zu viele Anfragen. Bitte warten Sie einen Augenblick.",
              });
              break;
            case 422:
              this.$refs.loginForm.setErrors(response.data.errors);
              break;
            default:
              this.$refs.loginForm.setErrors({
                password: ["Die Login Daten sind nicht korrekt."],
              });
              break;
          }
        });
    },
  },
};
</script>

<style scoped></style>
